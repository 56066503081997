import React from "react";
import {Route, Switch, useLocation} from "react-router-dom";
import {CSSTransition, TransitionGroup} from "react-transition-group";

import "./index.css";

import Background from "../background";
import NavBar from "../navbar";
import Home from "../../pages/home";
import FAQ from "../../pages/faq";
import NotFound from "../../pages/notfound";
import PrivacyPolicy from "../../pages/privacy-policy"
import Rules from "../../pages/rules";
import Application from "../../pages/application";
import MarkdownViewer from "../../pages/markdown-viewer";
import BugReport from "../../pages/bug-report";
import PlayerReport from "../../pages/player-report";
import BanAppeal from "../../pages/ban-appeal";
import StaffApplication from "../../pages/staff-application";
import Success from "../../pages/success";
import DeveloperApplication from "../../pages/developer-application";
import LawApplication from "../../pages/law-application";
import WikiSubmission from "../../pages/wiki-submission";

function Container() {
    let location = useLocation();

    return (
        <div className="container-wrapper">
            <Background />
            <NavBar />

            <TransitionGroup component={null}>
                <CSSTransition key={location.key} classNames="switch-fade" timeout={300}>
                    <Switch location={location}>
                        <Route exact path='/' component={Home} />
                        <Route exact path='/rules' component={Rules} />
                        <Route exact path='/faq' component={FAQ} />
                        <Route exact path='/privacy-policy' component={PrivacyPolicy} />
                        <Route exact path='/success' component={Success} />
                        <Route exact path='/application' component={Application} />
                        <Route exact path='/bug-report' component={BugReport} />
                        <Route exact path='/player-report' component={PlayerReport}/>
                        <Route exact path='/ban-appeal' component={BanAppeal}/>
                        <Route exact path='/staff-application' component={StaffApplication}/>
                        <Route exact path='/developer-application' component={DeveloperApplication}/>
                        <Route exact path='/law-application' component={LawApplication}/>
                        <Route exact path='/md-viewer' component={MarkdownViewer} />
                        <Route exact path='/wiki-submission' component={WikiSubmission} />
                        <Route path="*" component={NotFound} />
                    </Switch>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
}

export default Container;