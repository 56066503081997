import React, {Component} from "react";
import {Form, Field, Formik, ErrorMessage} from 'formik';
import { PersistFormikValues } from 'formik-persist-values';
import {Link} from "react-router-dom";
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {scroller} from 'react-scroll';
import axios from "axios";
import {hideCaptchaBadge, hideVideoBackground, makeNavBackgroundOpaque} from "../../../utility/elementFinder";

import "./index.css";

import TextArea from "../../elements/textarea";
import FormWrapper from "../../elements/form-wrapper";
import LoginStrategyButton, {LoginStrategies} from "../../elements/login-strategy-button";
import Warning from "../../elements/warning";
import ButtonWheel from "../../../assets/img/wheel-gold.svg";
import CustomSelect from "../../elements/cusotm-select";
import PassportHolder from "../../elements/passport-holder";

const initialValues = {
    steam_id: '',
    steam_name: '',
    discord_id: '',
    discord_name: '',
    discord_server: false,
    discord_icon: '',
    twitch_id: '',
    twitch_name: '',
    twitter_id: '',
    twitter_name: '',
    timezone: '',
    hear_about_us: '',
    ban_history: '',
    audio_setup: '',
    media: '',
    character_background: '',
    bank_robbery: '',
    experience: '',
    rdm: '',
    break_character: '',
    meta: '',
    saloon_space: '',
    read_rules: false,
    timeline: '',
    group: '',
    train: '',
    super: ''
}

const timezones = [
    {label: "(GMT -12:00) Eniwetok, Kwajalein", value: "-12:00"},
    {label: "(GMT -11:00) Midway Island, Samoa", value: "-11:00"},
    {label: "(GMT -10:00) Hawaii", value: "-10:00"},
    {label: "(GMT -9:30) Taiohae", value: "-09:50"},
    {label: "(GMT -9:00) Alaska", value: "-09:00"},
    {label: "(GMT -8:00) Pacific Time (US & Canada)", value: "-08:00"},
    {label: "(GMT -7:00) Mountain Time (US & Canada)", value: "-07:00"},
    {label: "(GMT -6:00) Central Time (US & Canada), Mexico City", value: "-06:00"},
    {label: "(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima", value: "-05:00"},
    {label: "(GMT -4:30) Caracas", value: "-04:50"},
    {label: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", value: "-04:00"},
    {label: "(GMT -3:30) Newfoundland", value: "-03:50"},
    {label: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "-03:00"},
    {label: "(GMT -2:00) Mid-Atlantic", value: "-02:00"},
    {label: "(GMT -1:00) Azores, Cape Verde Islands", value: "-01:00"},
    {label: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "+00:00"},
    {label: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "+01:00"},
    {label: "(GMT +2:00) Kaliningrad, South Africa", value: "+02:00"},
    {label: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "+03:00"},
    {label: "(GMT +3:30) Tehran", value: "+03:50"},
    {label: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "+04:00"},
    {label: "(GMT +4:30) Kabul", value: "+04:50"},
    {label: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "+05:00"},
    {label: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "+05:50"},
    {label: "(GMT +5:45) Kathmandu, Pokhara", value: "+05:75"},
    {label: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "+06:00"},
    {label: "(GMT +6:30) Yangon, Mandalay", value: "+06:50"},
    {label: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "+07:00"},
    {label: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "+08:00"},
    {label: "(GMT +8:45) Eucla", value: "+08:75"},
    {label: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "+09:00"},
    {label: "(GMT +9:30) Adelaide, Darwin", value: "+09:50"},
    {label: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "+10:00"},
    {label: "(GMT +10:30) Lord Howe Island", value: "+10:50"},
    {label: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "+11:00"},
    {label: "(GMT +11:30) Norfolk Island", value: "+11:50"},
    {label: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "+12:00"},
    {label: "(GMT +12:45) Chatham Islands", value: "+12:75"},
    {label: "(GMT +13:00) Apia, Nukualofa", value: "+13:00"},
    {label: "(GMT +14:00) Line Islands, Tokelau", value: "+14:00"}
];

class Application extends Component {
    constructor() {
        super();

        this.state = {
            charBackgroundCount: 0,
            bankRobberyCount: 0,
            saloonSpaceCount: 0
        }

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        hideCaptchaBadge(false);
        hideVideoBackground(true);
        makeNavBackgroundOpaque(true);
    }

    handleValidate(values) {
        const errors = {};

        if (!values.steam_id) errors.steam_id = 'You must attach a Steam account in order to submit your application.';
        if (!values.discord_id) errors.discord_id = 'You must attach a Discord account in order to submit your application.';
        if (!values.discord_server) errors.discord_server = "You must be a member of the Discord server in order to submit your application. If you have already joined, you must disconnect and reattach your Discord account."
        if (!values.timezone) errors.timezone = "You must select a Timezone before you can submit your application.";
        if (!values.read_rules) errors.read_rules = "You must confirm that you have read the rules before you can submit your application.";
        if (values.character_background.trim().split(' ').length > 100) errors.character_background = "Your character background must not exceed 100 words.";
        if (values.bank_robbery.trim().split(' ').length > 100) errors.character_background = "Your character background must not exceed 100 words.";
        if (values.saloon_space.trim().split(' ').length > 100) errors.character_background = "Your character background must not exceed 100 words.";

        return errors;
    }

    async handleSubmit(values, {setSubmitting, resetForm, setFieldError}) {
        let token = await this.props.googleReCaptchaProps.executeRecaptcha('submit_allowlist_application');

        console.log(token)

        let request = {
            form: "allowlist",
            captchaToken: token,
            values: values
        }

        await axios.post('/forms/allowlist', request).then(res => {
            if ('data' in res && res.data.status !== undefined) {
                let status = res.data.status;

                if (status === "success") {
                    resetForm();
                    this.props.history.push('/success?type=allowlist');
                    return;
                }
            }

            setFieldError("submit_error", "There was an error submitting your application, please try again. If this issue persists, please contact a WildRP staff member.")
        }).catch(error => {
            console.error(error);

            setFieldError("submit_error", "There was an error submitting your application, please try again. If this issue persists, please contact a WildRP staff member.")
        })

        setSubmitting(false);
    }

    render() {
        return (
            <FormWrapper>
                <div className="form-title">Allowlist Application</div>
                <Warning>
                    Being an allowlisted member of our community requires you to have both a Steam account and a Discord account with membership in our <b><a href="https://discord.gg/wildrp">Discord</a></b> server.
                    <br/><br/>
                    Furthermore, you <b>MUST</b> attach your Steam and Discord accounts to submit your application. Should you be allowlisted, your Steam ID will be used to give you access to our servers. We will also check to see if you are a member of the Discord server before allowing you to submit your application.
                    <br/><br/>
                    Lastly, you must be at least 18 years of age to be a part of the community and play on our servers.
                </Warning>
                <Formik initialValues={initialValues} onSubmit={this.handleSubmit} validate={this.handleValidate}>
                    {({isSubmitting}) => (
                        <Form>
                            <PassportHolder>
                                <LoginStrategyButton loginStrategy={LoginStrategies.STEAM} required={true} redirect="application"/>
                                <ErrorMessage name={"steam_id"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('steam_id', {smooth: true})}</p>}</ErrorMessage>
                                <LoginStrategyButton loginStrategy={LoginStrategies.DISCORD} required={true} redirect="application"/>
                                <ErrorMessage name={"discord_id"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('discord_id', {smooth: true})}</p>}</ErrorMessage>
                                <ErrorMessage name={"discord_server"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('discord_server', {smooth: true})}</p>}</ErrorMessage>
                                <LoginStrategyButton loginStrategy={LoginStrategies.TWITCH} required={false} redirect="application"/>
                                <LoginStrategyButton loginStrategy={LoginStrategies.TWITTER} required={false} redirect="application"/>
                            </PassportHolder>

                            <h1>General Questions</h1>

                            <p>Select a timezone for which fits your current online schedule the best. This will only be use to help prepare a time for your interview.</p>
                            <CustomSelect values={timezones} id={"timezone"} placeholder={"Select Timezone..."}/>
                            <ErrorMessage name={"timezone"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('timezone', {smooth: true})}</p>}</ErrorMessage>

                            <p>How did you hear about WildRP? If you were referred by someone, please include their name if they are ok with being mentioned!</p>
                            <TextArea id="hear_about_us" name="hear_about_us" rows="5" cols="25"/>

                            <p>Have you ever been banned from a community? And if so, why?</p>
                            <TextArea id="ban_history" name="ban_history" rows="5" cols="25"/>

                            <Warning>
                                <b>Note:</b> Headset microphones, Blue Snowball, Razer Seiren V2 X, Razer Seiren Mini, Samson Satellite, and all Tonor brand microphones are not considered to be up to standard and will result in an application denial.
                            </Warning>
                            <p>What brand and type of microphone do you use for roleplay and what hardware and/or software do you use for audio processing? If you record in a particularly noisy or reverberant environment, please describe what you do to mitigate such effects.</p>
                            <TextArea id="audio_setup" name="audio_setup" rows="5" cols="25"/>

                            <Warning>
                                <b>Allowlisted members of WildRP are expected to meet a set standard in both acting ability and roleplay know-how.</b>
                                <br/><br/>
                                While not required, submitting media showcasing your previous roleplaying or acting experience will greatly assist the staff team with the evaluation your application. Here are a few examples.
                                <br/><br/>
                                - Voice Acting Reel <br/>
                                - Roleplaying Playlist and/or Video Compilation <br/>
                                - Narration, Monologue, or Character Dialogue
                                <br/><br/>
                                You may also submit links to other forms of media not listed above. Twitch clips will be accepted; however, please be sure the are concise and have your character prominently featured.
                                <br/><br/>
                                Lastly, be creative!
                            </Warning>

                            <p>Include media links below. Please separate links with a comma. Only use permanent links, do not use Discord attachments.</p>
                            <TextArea id="media" name="media" rows="5" cols="25"/>

                            <h1>Character Background</h1>

                            <Warning>
                                <b>Make sure to read our server/community <Link to="/rules">Rules</Link> to understand the current server timeline and how your character fits into the world.</b>
                                <br/><br/>
                                All characters must be 18 years of age or older. Characters must <b>NOT</b> have a background or origin relating to slavery, the American Civil War, and/or Native American conflict.
                            </Warning>

                            <p>Describe your character and give us some background about them. (100 words max)</p>
                            <TextArea id="character_background" name="character_background" rows="14" cols="25" onInput={(event) => {
                                this.setState({charBackgroundCount: event.target.value.trim().split(' ').length})
                            }}/>
                            <p className="word-count">{`${this.state.charBackgroundCount}/100`}</p>
                            <ErrorMessage name={"character_background"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('character_background', {smooth: true})}</p>}</ErrorMessage>

                            <p>You duck as you hear a loud explosion coming from down the street, seems like someone is robbing the bank. In a few sentences, please describe what you do.</p>
                            <TextArea id="bank_robbery" name="bank_robbery" rows="5" cols="25" onInput={(event) => {
                                this.setState({bankRobberyCount: event.target.value.trim().split(' ').length})
                            }}/>
                            <p className="word-count">{`${this.state.bankRobberyCount}/100`}</p>
                            <ErrorMessage name={"bank_robbery"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('bank_robbery', {smooth: true})}</p>}</ErrorMessage>

                            <p>You've been bartending at the same saloon for the past several days. But today, as you walk in, you notice that someone else is now occupying the space. In a few sentences, please describe what you do.</p>
                            <TextArea id="saloon_space" name="saloon_space" rows="5" cols="25" onInput={(event) => {
                                this.setState({saloonSpaceCount: event.target.value.trim().split(' ').length})
                            }}/>
                            <p className="word-count">{`${this.state.saloonSpaceCount}/100`}</p>
                            <ErrorMessage name={"saloon_space"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('saloon_space', {smooth: true})}</p>}</ErrorMessage>

                            <h1>Roleplay Experience and Etiquette</h1>

                            <p>Do you have any past roleplay experience? And if so, please briefly describe that experience.</p>
                            <TextArea id="experience" name="experience" rows="5" cols="25"/>

                            <p>What is RDM? Can you provide an example?</p>
                            <TextArea id="rdm" name="rdm" rows="5" cols="25"/>

                            <p>When is it acceptable to break character?</p>
                            <TextArea id="break_character" name="break_character" rows="5" cols="25"/>

                            <p>In your own words, what is the definition of metagaming?</p>
                            <TextArea id="meta" name="meta" rows="5" cols="25"/>

                            <h1>Rules and Community Policies</h1>

                            <div className="checkbox-field">
                                <label>
                                    <Field type="checkbox" id="read_rules" name="read_rules"/>
                                    <span className="just-text">I have read the <a href="/rules" target="_blank">rules</a>.</span>
                                </label>
                            </div>
                            <ErrorMessage name={"read_rules"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('read_rules', {smooth: true})}</p>}</ErrorMessage>

                            <p>In the WildRP timeline could you give us an example of events or scenarios that would <b>not</b> have occurred?</p>
                            <TextArea id="timeline" name="timeline" rows="5" cols="25"/>

                            <p>How many people are allowed in an armed group at one time during a firefight?</p>
                            <TextArea id="group" name="group" rows="5" cols="25"/>

                            <p>When is it okay to remove the train driver NPC?</p>
                            <TextArea id="train" name="train" rows="5" cols="25"/>

                            <h1>Other Questions</h1>

                            <p>If you could have two superpowers and had to use them hand in hand, what would they be and why? Also what would be your kryptonite?</p>
                            <TextArea id="super" name="super" rows="5" cols="25"/>

                            <button type="submit" disabled={isSubmitting}>
                                Submit Application
                                <div className="wheel">
                                    <img src={ButtonWheel} alt={ButtonWheel}/>
                                </div>
                            </button>
                            <ErrorMessage name={"submit_error"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('submit_error', {smooth: true})}</p>}</ErrorMessage>

                            <PersistFormikValues name="allowlist-application" ignoreValues={["steam_id", "steam_name", "discord_id", "discord_name", "discord_icon", "discord_server", "twitch_id", "twitch_name", "twitter_id", "twitter_name"]} storage={"sessionStorage"} persistInvalid={true}/>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        );
    }
}

export default withGoogleReCaptcha(Application);
