import React, {Component} from "react";
import {Form, Formik, ErrorMessage} from 'formik';
import { PersistFormikValues } from 'formik-persist-values';
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {scroller} from 'react-scroll';
import axios from "axios";
import {hideCaptchaBadge, hideVideoBackground, makeNavBackgroundOpaque} from "../../../utility/elementFinder";

import "./index.css";

import TextArea from "../../elements/textarea";
import FormWrapper from "../../elements/form-wrapper";
import Warning from "../../elements/warning";
import TextField from "../../elements/textfield";
import ButtonWheel from "../../../assets/img/wheel-gold.svg";
import EmailField from "../../elements/emailfield";
import DateField from "../../elements/datefield";

const initialValues = {
    discord_id: '',
    email: '',
    character_name: '',
    ban_reason: '',
    date_of_ban: '',
    why_unban: ''
}

class BanAppeal extends Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        hideCaptchaBadge(false);
        hideVideoBackground(true);
        makeNavBackgroundOpaque(true);
    }

    handleValidate(values) {
        const errors = {};

        if (!values.discord_id.match(/^[^#]{2,32}#\d{4}$/g)) errors.discord_id = 'A valid Discord identifier must be attached.';
        if (!values.email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) errors.email = 'A valid and active email address must be attached.';
        if (!values.ban_reason) errors.ban_reason = 'You must include the reason you have been banned.';
        if (!values.date_of_ban) errors.date_of_ban = 'You must include the date on which you were banned.';
        if (!values.why_unban) errors.why_unban = "You must describe why you believe you should be unbanned and allowed back into the community.";

        return errors;
    }

    async handleSubmit(values, {setSubmitting, resetForm, setFieldError}) {
        let token = await this.props.googleReCaptchaProps.executeRecaptcha('submit_banappeal');

        console.log(token)

        let request = {
            form: "banappeal",
            captchaToken: token,
            values: values
        }

        await axios.post('/forms/banappeal', request).then(res => {
            if ('data' in res && res.data.status !== undefined) {
                let status = res.data.status;

                if (status === "success") {
                    resetForm();
                    this.props.history.push('/success?type=banappeal');
                    return;
                }
            }

            setFieldError("submit_error", "There was an error submitting your report, please try again. If this issue persists, please contact a WildRP staff member.")
        }).catch(error => {
            console.error(error);

            setFieldError("submit_error", "There was an error submitting your report, please try again. If this issue persists, please contact a WildRP staff member.")
        })

        setSubmitting(false);
    }

    render() {
        return (
            <FormWrapper>
                <div className="form-title">Ban Appeal</div>
                <Warning>
                    All post-ban communication with the WildRP staff team will only occur via electronic mail communication. As such, we require all ban appeals to have a valid and active email attached through which our team can contact you.
                    <br/><br/>
                    WildRP staff will never contact you from an email that does not end with the "wildrp.com" domain.
                </Warning>
                <Formik initialValues={initialValues} onSubmit={this.handleSubmit} validate={this.handleValidate}>
                    {({isSubmitting, values}) => (
                        <Form>
                            <h1>General Questions</h1>

                            <p>Your Discord identifier.</p>
                            <TextField id="discord_id" name="discord_id" placeholder="discord#0000"/>
                            <ErrorMessage name={"discord_id"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('discord_id', {smooth: true})}</p>}</ErrorMessage>

                            <p>A valid and active email address.</p>
                            <EmailField id="email" name="email" placeholder="your email@example.com"/>
                            <ErrorMessage name={"email"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('email', {smooth: true})}</p>}</ErrorMessage>

                            <h1>Ban Information</h1>

                            <p>What was the date of your ban?</p>
                            <DateField id="date_of_ban" name="date_of_ban"/>
                            <ErrorMessage name={"date_of_ban"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('date_of_ban', {smooth: true})}</p>}</ErrorMessage>

                            <p>If your ban involved a specific character of yours, please enter their name.</p>
                            <TextField id="character_name" name="character_name"/>

                            <p>What was the reason for your ban?</p>
                            <TextArea id="ban_reason" name="ban_reason" rows="5" cols="25"/>
                            <ErrorMessage name={"ban_reason"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('ban_reason', {smooth: true})}</p>}</ErrorMessage>

                            <h1>Appeal Information</h1>

                            <p>Please describe why you feel you should be unbanned.</p>
                            <TextArea id="why_unban" name="why_unban" rows="5" cols="25"/>
                            <ErrorMessage name={"why_unban"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('why_unban', {smooth: true})}</p>}</ErrorMessage>

                            <button type="submit" disabled={isSubmitting}>
                                Submit Appeal
                                <div className="wheel">
                                    <img src={ButtonWheel} alt={ButtonWheel}/>
                                </div>
                            </button>
                            <ErrorMessage name={"submit_error"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('submit_error', {smooth: true})}</p>}</ErrorMessage>

                            <PersistFormikValues name="ban-appeal" storage={"sessionStorage"} persistInvalid={true}/>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        );
    }
}

export default withGoogleReCaptcha(BanAppeal);