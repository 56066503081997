import React, {Component} from "react";

import "./index.css";

import Content from "../../elements/content";
import {hideCaptchaBadge, hideVideoBackground, makeNavBackgroundOpaque} from "../../../utility/elementFinder";

export default class NotFound extends Component {
    componentDidMount() {
        hideCaptchaBadge(true);
        hideVideoBackground(false);
        makeNavBackgroundOpaque(true);
    }

    render() {
        return (
            <Content>
                <div className="not-found-wrapper">
                <span className="four-o-four">
                    404
                </span>
                    <span className="blurb">
                    "If you find yourself in a hole, the first thing to do is stop digging." - Will Rogers
                </span>
                </div>
            </Content>
        );
    }
}