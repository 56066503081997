import React, {Component} from "react";

import "./index.css";
import Content from "../content";
import {isMobile} from "react-device-detect";

export default class FormWrapper extends Component {
    render() {
        return (
            <Content>
                <div className={`form-wrapper ${isMobile ? "mobile-form" : ""}`}>
                    {this.props.children}
                </div>
            </Content>
        );
    }
}
