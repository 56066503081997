import {isMobile} from "react-device-detect";

export function hideCaptchaBadge(hide:boolean) {
    let checkCaptchaExist = setInterval(function () {
        let grecaptcha = document.querySelector('.grecaptcha-badge');

        if (grecaptcha !== undefined && grecaptcha !== null) {
            grecaptcha.style.display = isMobile ? 'none' : (hide ? 'none' : 'block');

            clearInterval(checkCaptchaExist);
        }
    }, 100);
}

export function hideVideoBackground(hide:boolean) {
    let checkBackgroundExist = setInterval(function () {
        let videoBackground = document.querySelector('.video-background');
        let imageBackground = document.querySelector('.image-background');

        if (videoBackground !== undefined && videoBackground !== null && imageBackground !== undefined && imageBackground !== null) {
            if (hide) {
                videoBackground.classList.add("fade");
                imageBackground.classList.remove("fade");
            } else {
                videoBackground.classList.remove("fade");
                imageBackground.classList.add("fade");
            }

            clearInterval(checkBackgroundExist);
        }
    }, 100);
}

export function makeNavBackgroundOpaque(opaque:boolean) {
    let checkBackgroundExist = setInterval(function () {

        let navBackground = document.querySelector('.dropdown-background');

        if (navBackground !== undefined && navBackground !== null) {
            if (opaque) {
                navBackground.classList.add("opaque");
            } else {
                navBackground.classList.remove("opaque");
            }

            clearInterval(checkBackgroundExist);
        }
    }, 100);
}
