import React from "react";
import {isBrowser} from "react-device-detect";

import "./index.css";

import Video from "../../../assets/img/background-video.webm"
import Image from "../../../assets/img/background.png"

function Background () {
    if (isBrowser) {
        return (
            <div className="background-wrapper">
                <video className={`video-background`} src={Video} preload="auto" autoPlay muted loop />
                <img className={`image-background`} src={Image} alt={""}/>
            </div>
        )
    } else {
        return (
            <div className="background-wrapper">
                <img className="image-background" src={Image} alt={""}/>
            </div>
        )
    }
}

export default Background;
