import React, {Component} from "react";

import "./index.css";

export default class Content extends Component {
    render() {
        return (
            <div className="content-wrapper">
                {this.props.children}
            </div>
        );
    }
}
