import React, {Component} from "react";
import Select from "react-dropdown-select";
import _ from "lodash";

import "./index.css";
import {connect} from "formik";

class CustomSelect extends Component {
    render() {

        let value =  _.find(this.props.values, ['value', this.props.formik.values[this.props.id]]);

        return (
            <Select className="custom-select-wrapper" id={this.props.id} name={this.props.id} options={this.props.values} values={value !== undefined ? [value] : []} placeholder={this.props.placeholder} searchable={false} onChange={(event) => {this.props.formik.setFieldValue(this.props.id, event[0] !== undefined ? event[0].value : "")}}/>
        );
    }
}

export default connect(CustomSelect);