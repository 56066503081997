import React, {Component} from "react";
import {Form, Formik, ErrorMessage} from 'formik';
import {PersistFormikValues} from 'formik-persist-values';
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {scroller} from 'react-scroll';
import axios from "axios";
import {hideCaptchaBadge, hideVideoBackground, makeNavBackgroundOpaque} from "../../../utility/elementFinder";

import "./index.css";

import TextArea from "../../elements/textarea";
import FormWrapper from "../../elements/form-wrapper";
import LoginStrategyButton, {LoginStrategies} from "../../elements/login-strategy-button";
import TextField from "../../elements/textfield";
import ButtonWheel from "../../../assets/img/wheel-gold.svg";
import CustomSelect from "../../elements/cusotm-select";
import PassportHolder from "../../elements/passport-holder";

const initialValues = {
    steam_id: '',
    steam_name: '',
    discord_id: '',
    discord_name: '',
    twitch_id: '',
    twitch_name: '',
    experience: '',
    character_name: '',
    why_hire: '',
    which_role: '',
    how_capable: '',
    corrupt_rp: '',
    local_outlaw: '',
    shoot_to_kill: '',
    why_leo: ''

}

const teams = [
    {label: "Deputy", value: "deputy"},
    {label: "Sheriff", value: "sheriff"},
]

class LawApplication extends Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        hideCaptchaBadge(false);
        hideVideoBackground(true);
        makeNavBackgroundOpaque(true);
    }

    handleValidate(values) {
        const errors = {};

        if (!values.steam_id) errors.steam_id = 'You must attach a Steam account in order to submit a law application.';
        if (!values.discord_id) errors.discord_id = 'You must attach a Discord account in order to submit a law application.';
        if (!values.character_name) errors.character_name = 'You must include the name of the character you want to apply with.';
        if (!values.which_role) errors.which_role = 'You must select a role to apply for.';

        return errors;
    }

    async handleSubmit(values, {setSubmitting, resetForm, setFieldError}) {
        let token = await this.props.googleReCaptchaProps.executeRecaptcha('submit_lawapplication');

        console.log(token)

        let request = {
            form: "lawapplication",
            captchaToken: token,
            values: values
        }

        await axios.post('/forms/lawapplication', request).then(res => {
            if ('data' in res && res.data.status !== undefined) {
                let status = res.data.status;

                if (status === "success") {
                    resetForm();
                    this.props.history.push('/success?type=lawapplication');
                    return;
                }
            }

            setFieldError("submit_error", "There was an error submitting your application, please try again. If this issue persists, please contact a WildRP staff member.")
        }).catch(error => {
            console.error(error);

            setFieldError("submit_error", "There was an error submitting your application, please try again. If this issue persists, please contact a WildRP staff member.")
        })

        setSubmitting(false);
    }

    render() {
        return (
            <FormWrapper>
                <div className="form-title">Law Application</div>
                <Formik initialValues={initialValues} onSubmit={this.handleSubmit} validate={this.handleValidate}>
                    {({isSubmitting, values}) => (
                        <Form>
                            <PassportHolder>
                                <LoginStrategyButton loginStrategy={LoginStrategies.STEAM} required={true} redirect="law-application"/>
                                <ErrorMessage name={"steam_id"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('steam_id', {smooth: true})}</p>}</ErrorMessage>
                                <LoginStrategyButton loginStrategy={LoginStrategies.DISCORD} required={true} redirect="law-application"/>
                                <ErrorMessage name={"discord_id"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('discord_id', {smooth: true})}</p>}</ErrorMessage>
                                <LoginStrategyButton loginStrategy={LoginStrategies.TWITCH} required={false} redirect="law-application"/>
                            </PassportHolder>

                            <h1>About you</h1>

                            <p>Do you have past Law Enforcement experience in roleplay or IRL? If so, for how long?</p>
                            <TextArea id="experience" name="experience" rows="5" cols="25"/>

                            <p>Why do you want to join the LEO team?</p>
                            <TextArea id="why_leo" name="why_leo" rows="5" cols="25"/>

                            <p>Do you plan on being corrupt in any way? If yes, what are your reasons for doing so?</p>
                            <TextArea id="corrupt_rp" name="corrupt_rp" rows="5" cols="25"/>

                            <h1>Character Details</h1>

                            <p>What is the full name of the character you are applying with?</p>
                            <TextField id="character_name" name="character_name"/>
                            <ErrorMessage name={"character_name"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('character_name', {smooth: true})}</p>}</ErrorMessage>

                            <p>Why should this character be hired for Law Enforcement? Who might (in character) recommend you?</p>
                            <TextArea id="why_hire" name="why_hire" rows="5" cols="25"/>

                            <p>Which LEO role are you applying for?</p>
                            <CustomSelect values={teams} id={"which_role"} placeholder={"Select Role..."}/>
                            <ErrorMessage name={"which_role"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('which_role', {smooth: true})}</p>}</ErrorMessage>

                            <p>In your role, what tasks do you expect to perform? How capable is your character of performing these tasks?</p>
                            <TextArea id="how_capable" name="how_capable" rows="5" cols="25"/>

                            <h1>Some Hypotheticals</h1>

                            <p>You have shot down the neighborhood outlaw as he was getting rowdy in the saloon and drew his weapon. What are your next steps?</p>
                            <TextArea id="local_outlaw" name="local_outlaw" rows="5" cols="25"/>

                            <p>When is it appropriate for you, as an LEO of the server, to shoot another player with the intention to kill them?</p>
                            <TextArea id="shoot_to_kill" name="shoot_to_kill" rows="5" cols="25"/>

                            <button type="submit" disabled={isSubmitting}>
                                Submit Application
                                <div className="wheel">
                                    <img src={ButtonWheel} alt={ButtonWheel}/>
                                </div>
                            </button>
                            <ErrorMessage name={"submit_error"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('submit_error', {smooth: true})}</p>}</ErrorMessage>

                            <PersistFormikValues name="law-application" ignoreValues={["steam_id", "steam_name", "discord_id", "discord_name", "discord_icon", "discord_server", "twitch_name", "twitch_id"]} storage={"sessionStorage"} persistInvalid={true}/>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        );
    }
}

export default withGoogleReCaptcha(LawApplication);