import React, {Children, cloneElement, isValidElement, useEffect, useState} from "react";
import {connect} from 'formik';

import "./index.css";

function PassportHolder({children}) {
    const [isLoading, setLoading] = useState(true);
    const [accountInfo, setAccountInfo] = useState();

    useEffect( () => {
        async function fetchData() {
            try {
                let response = await fetch('/account');
                let json = await response.json();
                setAccountInfo(json);
                setLoading(false)
            } catch (error) {
                console.log(error);
            }
        }

        fetchData().then(r => {})
    }, [])

    if (isLoading)
    {
        return children
    }
    else
    {
        const childrenWithProps = Children.map(children, child => {
            if (isValidElement(child)) {
                return cloneElement(child, { accountInfo: accountInfo })
            }

            return child;
        });

        return <React.Fragment>{childrenWithProps}</React.Fragment>
    }
}

export default connect(PassportHolder);