import React, {Component} from "react";
import {Link, scrollSpy} from 'react-scroll'
import {BrowserView, isMobile} from "react-device-detect";
import marked from "marked";
import DOMPurify from 'dompurify';

import "./index.css";
import Content from "../content";

type ArticleProps = {
    markdownFilePath?: string,
    markdownRaw?: string
}

type ArticleStates = {
    markdown: string,
    tokens: any
}

export default class Article extends Component<ArticleProps, ArticleStates> {
    constructor(props: any) {
        super(props);

        this.state = {
            markdown: "",
            tokens: []
        }
    }

    // Article data must be read before mount to prevent state update once component is visible.
    UNSAFE_componentWillMount() {
        if (this.props.markdownFilePath !== undefined)
        {
            fetch(this.props.markdownFilePath)
                .then(response => {
                    return response.text()
                })
                .then(text => {
                    this.setState({
                        markdown: marked(text),
                        tokens: marked.lexer(text)
                    })
                })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: Readonly<ArticleProps>, nextContext: any) {
        if (nextProps.markdownRaw !== undefined) {
            this.setState({
                markdown: marked(nextProps.markdownRaw),
                tokens: marked.lexer(nextProps.markdownRaw)
            })
        }
    }

    componentDidMount() {
        scrollSpy.update();
    }

    buildArticleNavigation() {
        const items = [];

        for (let i = 0; i < this.state.tokens.length; i++) {
            let token = this.state.tokens[i];

            if (token.type !== "heading") continue;

            let depth:number = token.depth;
            let text:string = token.text;
            let id:string = text.toLowerCase()
                .replaceAll(' ', '-')
                .replaceAll('/', '')
                .replaceAll(',', '')
                .replaceAll('(', '')
                .replaceAll(')', '')
                .replaceAll('?', '');

            if (i === 0)
            {
                items.push((
                    <div key={i} className={`article-navigation-link depth-${depth}`}>
                        <Link activeClass="scroll-active" to={"nav"} spy={true} smooth={true} duration={500} isDynamic={true}>
                            {text}
                        </Link>
                    </div>
                ))
            }
            else
            {
                items.push((
                    <div key={i} className={`article-navigation-link depth-${depth}`}>
                        <Link activeClass="scroll-active" to={id} spy={true} smooth={true} duration={500} isDynamic={true}>
                            {text}
                        </Link>
                    </div>
                ))
            }
        }

        return items;
    }

    render() {
        return (
            <Content>
                <div className="article-wrapper">
                    <BrowserView className="article-navigation-wrapper">
                        <div className="article-navigation">
                            {this.buildArticleNavigation()}
                        </div>
                    </BrowserView>

                    <section className={`article-content ${isMobile ? "mobile-article" : ""}`} id="article-content">
                        <article dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(this.state.markdown)}}/>
                    </section>
                </div>
            </Content>
        );
    }
}