import React, {Component} from "react";
import {animateScroll as scroll} from 'react-scroll'
import {BrowserView, MobileView} from "react-device-detect";
import {TwitterTimelineEmbed} from "react-twitter-embed";

import "./index.css";

import Content from "../../elements/content";
import {hideCaptchaBadge, hideVideoBackground, makeNavBackgroundOpaque} from "../../../utility/elementFinder";
import Welcome from "../../../assets/img/welcome.png";
import Launcher from "../../../assets/img/launcher.png";
import NewDay from "../../../assets/img/new-day.png";
import ButtonWheel from "../../../assets/img/wheel-gold.svg";

export default class Home extends Component {
    componentDidMount() {
        hideCaptchaBadge(true);
        hideVideoBackground(false);
        makeNavBackgroundOpaque(false);
    }

    render() {
        return (
            <Content>
                <BrowserView className="home-wrapper">
                    <div className="landing">
                        <img className="new-day" src={NewDay} alt={""}/>
                        <img className="welcome" src={Welcome} alt={""}/>

                    </div>

                    <p>WildRP is a premiere roleplay community. We aim to bring an atmosphere of high quality roleplay that encourages in-depth character development while providing a wide range of features and constant updates.</p>

                    <div className="app-plug">
                        <button type="submit" onClick={() => {
                            scroll.scrollToTop();
                            this.props.history.push('/application');
                        }}>
                            Allowlist Application
                            <div className="wheel">
                                <img src={ButtonWheel} alt={ButtonWheel}/>
                            </div>
                        </button>
                    </div>

                    <h1>Download the launcher</h1>

                    <p className="blurb">Our custom launcher is built to get you up and running and into our servers as quick as possible!</p>

                    <a href="https://storage.googleapis.com/wildrp-launcher-downloads/WildRPLauncherSetup.exe">
                        <img className="launcher" src={Launcher} alt={""}/>
                    </a>

                    <br/>

                    <a href="https://storage.googleapis.com/wildrp-launcher-downloads/WildRPLauncherSetup.exe">alternative download link</a>

                    <br/>
                    <br/>
                    <br/>
                    <br/>

                    <div className="socials">
                        <div className="discord">
                            <h1>Join the discord</h1>
                            <iframe src="https://discord.com/widget?id=641831604624424962&theme=dark" title="discord" width="350" height="500" frameBorder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"/>
                        </div>

                        <div className="twitter">
                            <h1>Follow us</h1>
                            <TwitterTimelineEmbed
                                theme="dark"
                                sourceType="profile"
                                screenName="WildRPOfficial"
                                options={{height: 495, width: 350,}}
                            />
                        </div>
                    </div>

                    <br/>
                    <br/>

                    <p className="blurb">We run our community primarily via Discord. All announcements, application status updates, developer previews, and other general communication can be found there. You must be a part of our discord community in order to submit an allowlist application.</p>
                </BrowserView>

                <MobileView className="home-wrapper mobile">
                    <div className="landing mobile">
                        <img  src={NewDay} alt={""}/>
                        <img  src={Welcome} alt={""}/>
                    </div>

                    <p>WildRP is a premiere roleplay community. We aim to bring an atmosphere of high quality roleplay that encourages in-depth character development while providing a wide range of features and constant updates.</p>

                    <div className="app-plug mobile">
                        <button type="submit" onClick={() => {
                            scroll.scrollToTop();
                            this.props.history.push('/application');
                        }}>
                            Allowlist Application
                            <div className="wheel">
                                <img src={ButtonWheel} alt={ButtonWheel}/>
                            </div>
                        </button>
                    </div>

                    <div className="socials mobile">
                        <div className="discord">
                            <h1>Join the discord</h1>
                            <iframe src="https://discord.com/widget?id=641831604624424962&theme=dark" title="discord" width="100%" height="500" frameBorder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"/>
                        </div>

                        <div className="twitter">
                            <h1>Follow us</h1>
                            <TwitterTimelineEmbed
                                theme="dark"
                                sourceType="profile"
                                screenName="WildRPOfficial"
                                options={{height: 495, width: 350,}}
                            />
                        </div>
                    </div>
                </MobileView>
            </Content>
        );
    }
}
