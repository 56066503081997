import React, {Component} from "react";
import {Form, Formik, ErrorMessage} from 'formik';
import { PersistFormikValues } from 'formik-persist-values';
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {scroller} from 'react-scroll';
import axios from "axios";
import {hideCaptchaBadge, hideVideoBackground, makeNavBackgroundOpaque} from "../../../utility/elementFinder";

import "./index.css";

import TextArea from "../../elements/textarea";
import FormWrapper from "../../elements/form-wrapper";
import LoginStrategyButton, {LoginStrategies} from "../../elements/login-strategy-button";
import Warning from "../../elements/warning";
import TextField from "../../elements/textfield";
import ButtonWheel from "../../../assets/img/wheel-gold.svg";
import PassportHolder from "../../elements/passport-holder";

const initialValues = {
    discord_id: '',
    offending_user: '',
    date_time: '',
    other_players: '',
    rules_broken: '',
    written_evidence: '',
    evidence_one: '',
    evidence_two: '',
    evidence_three: ''
}

class PlayerReport extends Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        hideCaptchaBadge(false);
        hideVideoBackground(true);
        makeNavBackgroundOpaque(true);
    }

    handleValidate(values) {
        const errors = {};

        if (!values.discord_id) errors.discord_id = 'You must attach a Discord account in order to submit a player report.';
        if (!values.offending_user) errors.offending_user = 'Player id(s) and/or name(s) must be reported in order for staff to investigate the report.';
        if (!values.date_time) errors.date_time = 'A general timeframe for when this offense took place must be included to aid staff investigation.';
        if (!values.rules_broken) errors.rules_broken = 'You must include a short description or list of the rule(s) broken.';
        if (!values.written_evidence) errors.written_evidence = "You must describe the event where the offense took place and how the player(s) broken the rule(s).";

        return errors;
    }

    async handleSubmit(values, {setSubmitting, resetForm, setFieldError}) {
        let token = await this.props.googleReCaptchaProps.executeRecaptcha('submit_playerreport');

        console.log(token)

        let request = {
            form: "playerreport",
            captchaToken: token,
            values: values
        }

        await axios.post('/forms/playerreport', request).then(res => {
            if ('data' in res && res.data.status !== undefined) {
                let status = res.data.status;

                if (status === "success") {
                    resetForm();
                    this.props.history.push('/success?type=playerreport');
                    return;
                }
            }

            setFieldError("submit_error", "There was an error submitting your report, please try again. If this issue persists, please contact a WildRP staff member.")
        }).catch(error => {
            console.error(error);

            setFieldError("submit_error", "There was an error submitting your report, please try again. If this issue persists, please contact a WildRP staff member.")
        })

        setSubmitting(false);
    }

    render() {
        return (
            <FormWrapper>
                <div className="form-title">Player Report</div>
                <Warning>
                    Staff may contact you after submitting your report if we need more information.
                </Warning>
                <Formik initialValues={initialValues} onSubmit={this.handleSubmit} validate={this.handleValidate}>
                    {({isSubmitting, values}) => (
                        <Form>
                            <PassportHolder>
                                <LoginStrategyButton loginStrategy={LoginStrategies.DISCORD} required={true} redirect="player-report"/>
                                <ErrorMessage name={"discord_id"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('discord_id', {smooth: true})}</p>}</ErrorMessage>
                            </PassportHolder>

                            <h1>General Questions</h1>

                            <p>Player id and/or character name of offending user(s).</p>
                            <TextField id="offending_user" name="offending_user"/>
                            <ErrorMessage name={"offending_user"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('offending_user', {smooth: true})}</p>}</ErrorMessage>

                            <p>Date(s) and time(s) for when offence(s) took place.</p>
                            <TextField id="date_time" name="date_time"/>
                            <ErrorMessage name={"date_time"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('date_time', {smooth: true})}</p>}</ErrorMessage>

                            <p>Other players involved.</p>
                            <TextField id="other_players" name="other_players"/>

                            <h1>Rule breaks and Evidence</h1>

                            <p>Please list or paraphrase the <a href="/rules" target="_blank">rule(s)</a> that were broken.</p>
                            <TextArea id="rules_broken" name="rules_broken" rows="5" cols="25"/>
                            <ErrorMessage name={"rules_broken"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('rules_broken', {smooth: true})}</p>}</ErrorMessage>

                            <p>How did the offending player(s) break the rule(s)?</p>
                            <TextArea id="written_evidence" name="written_evidence" rows="5" cols="25"/>
                            <ErrorMessage name={"written_evidence"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('written_evidence', {smooth: true})}</p>}</ErrorMessage>

                            <p>Please submit any evidence you may have via screenshots, recordings, or livestream VODs. Please upload your evidence to an external hosting site and share the link the fields below. If possible, do <b>NOT</b> submit a link to a discord attachment.</p>
                            <TextField id="evidence_one" name="evidence_one" placeholder="Evidence #1"/>

                            {(values.evidence_one !== undefined && values.evidence_one !== "") &&
                            <TextField id="evidence_two" name="evidence_two" placeholder="Evidence #2"/>
                            }

                            {(values.evidence_two !== undefined && values.evidence_two !== "") &&
                            <TextField id="evidence_three" name="evidence_three" placeholder="Evidence #3"/>
                            }

                            <button type="submit" disabled={isSubmitting}>
                                Submit Report
                                <div className="wheel">
                                    <img src={ButtonWheel} alt={ButtonWheel}/>
                                </div>
                            </button>
                            <ErrorMessage name={"submit_error"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('submit_error', {smooth: true})}</p>}</ErrorMessage>

                            <PersistFormikValues name="player-report" storage={"sessionStorage"} persistInvalid={true}/>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        );
    }
}

export default withGoogleReCaptcha(PlayerReport);