import React, {Component} from "react";
import {Form, Formik, ErrorMessage} from 'formik';
import { PersistFormikValues } from 'formik-persist-values';
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {scroller} from 'react-scroll';
import axios from "axios";
import {hideCaptchaBadge, hideVideoBackground, makeNavBackgroundOpaque} from "../../../utility/elementFinder";

import "./index.css";

import TextArea from "../../elements/textarea";
import FormWrapper from "../../elements/form-wrapper";
import LoginStrategyButton, {LoginStrategies} from "../../elements/login-strategy-button";
import Warning from "../../elements/warning";
import TextField from "../../elements/textfield";
import ButtonWheel from "../../../assets/img/wheel-gold.svg";
import PassportHolder from "../../elements/passport-holder";
import CustomSelect from "../../elements/cusotm-select";

const initialValues = {
    discord_id: '',
    offending_user: '',
    date_time: '',
    other_players: '',
    rules_broken: '',
    written_evidence: '',
    evidence_one: '',
    evidence_two: '',
    evidence_three: ''
}

const submissionTypes = [
    {label: "Character", value: "character"},
    {label: "Organization", value: "organization"},
    //{label: "Battle", value: "battle"}
]

const characterStatuses = [
    {label: "Alive", value: "alive"},
    {label: "Deceased", value: "deceased"},
    {label: "Unknown", value: "unknown"}
]

class WikiSubmission extends Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        hideCaptchaBadge(false);
        hideVideoBackground(true);
        makeNavBackgroundOpaque(true);
    }

    handleValidate(values) {
        const errors = {};

        if (!values.discord_id) errors.discord_id = 'You must attach a Discord account in order to submit a player report.';

        return errors;
    }

    async handleSubmit(values, {setSubmitting, resetForm, setFieldError}) {
        let token = await this.props.googleReCaptchaProps.executeRecaptcha('submit_wikisubmission');

        console.log(token)

        let request = {
            form: "wikisubmission",
            captchaToken: token,
            values: values
        }

        await axios.post('/forms/wikisubmission', request).then(res => {
            if ('data' in res && res.data.status !== undefined) {
                let status = res.data.status;

                if (status === "success") {
                    resetForm();
                    this.props.history.push('/success?type=wikisubmission');
                    return;
                }
            }

            setFieldError("submit_error", "There was an error with submission, please try again. If this issue persists, please contact a WildRP staff member.")
        }).catch(error => {
            console.error(error);

            setFieldError("submit_error", "There was an error with submission, please try again. If this issue persists, please contact a WildRP staff member.")
        })

        setSubmitting(false);
    }

    render() {
        return (
            <FormWrapper>
                <div className="form-title">Wiki Submission</div>
                <Warning>
                    Staff may contact you after submission if more information is needed. Once the corresponding page is created on the wiki, you will be free to make changes.
                </Warning>
                <Formik initialValues={initialValues} onSubmit={this.handleSubmit} validate={this.handleValidate}>
                    {({isSubmitting, values}) => (
                        <Form>
                            <PassportHolder>
                                <LoginStrategyButton loginStrategy={LoginStrategies.DISCORD} required={true} redirect="wiki-submission"/>
                                <ErrorMessage name={"discord_id"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('discord_id', {smooth: true})}</p>}</ErrorMessage>
                            </PassportHolder>

                            <CustomSelect values={submissionTypes} id={"submission_type"} placeholder={"Select Submission Type..."}/>
                            <ErrorMessage name={"submission_type"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('submission_type', {smooth: true})}</p>}</ErrorMessage>

                            {values.submission_type === "character" &&
                                <React.Fragment>
                                    <h1>Character Bio</h1>
                                    <p>Character Name, used for title of wiki page:</p>
                                    <TextField id="character_name" name="character_name"/>

                                    <p>Full name:</p>
                                    <TextField id="character_legal_name" name="character_legal_name" placeholder="Full, legal name of a character"/>

                                    <p>Status:</p>
                                    <CustomSelect values={characterStatuses} id={"character_status"}/>

                                    <p>Alias:</p>
                                    <TextField id="character_alias" name="character_alias" placeholder="Name used instead of their regular name"/>

                                    <p>Nickname:</p>
                                    <TextField id="character_nickname" name="character_nickname" placeholder="Name used in addition to their regular name"/>

                                    <p>Nationality:</p>
                                    <TextField id="character_nationality" name="character_nationality"/>

                                    <p>Gender:</p>
                                    <TextField id="character_gender" name="character_gender"/>

                                    <p>Title:</p>
                                    <TextField id="character_titles" name="character_titles" placeholder="Generally job titles."/>

                                    <p>Occupation:</p>
                                    <TextField id="character_occupation" name="character_occupation" placeholder="Current or former legally held jobs or criminal activities."/>

                                    <p>Affiliation:</p>
                                    <TextField id="character_affiliation" name="character_affiliation" placeholder="Organizations or criminal outfits"/>

                                    <p>Relatives:</p>
                                    <TextField id="character_relatives" name="character_relatives" placeholder="Denote relationship to the character"/>

                                    <p>Birth date:</p>
                                    <TextField id="character_birth_date" name="character_birth_date"/>

                                    <p>Birth place:</p>
                                    <TextField id="character_birth_place" name="character_birth_place"/>

                                    <p>Age:</p>
                                    <TextField id="character_age" name="character_age"/>

                                    <p>Significant Other:</p>
                                    <TextField id="character_sig_other" name="character_sig_other"/>

                                    <p>Horse(s):</p>
                                    <TextField id="character_horse" name="character_horse"/>

                                    <p>Portrayed by:</p>
                                    <TextField id="character_portrayed" name="character_portrayed"/>

                                    {values.character_status === "deceased" &&
                                        <React.Fragment>
                                            <h1>Death Certificate</h1>

                                            <p>Death date:</p>
                                            <TextField id="character_death_date" name="character_death_date"/>

                                            <p>Cause of death:</p>
                                            <TextField id="character_death_cause" name="character_death_cause" placeholder="List the injury/incident that resulted in death. Example: Head injury from falling off a cliff"/>

                                            <p>Place of death:</p>
                                            <TextField id="character_death_place" name="character_death_place" placeholder="Location where the character passed away"/>

                                            <p>Burial site:</p>
                                            <TextField id="character_burial_site" name="character_burial_site" placeholder="Specific location the character is buried."/>
                                        </React.Fragment>
                                    }

                                    <h1>Character Description</h1>

                                    <p>Early Life:</p>
                                    <TextArea id="character_early_life" name="character_early_life" rows="3" cols="25" placeholder="Description of any relevant events in the character's backstory"/>

                                    <p>Events in Saint's Crossing:</p>
                                    <TextArea id="character_events" name="character_events" rows="6" cols="25" placeholder="Description of events that have happened on the server. These should generally be long-past events and not overly relevant to any current storylines. PLEASE be cognizant of meta and spoilers and do not give away important information for currently unfolding stories."/>

                                    <p>Personality:</p>
                                    <TextArea id="character_personality" name="character_personality" rows="3" cols="25" placeholder="Description of who this character is"/>

                                    <p>Appearance:</p>
                                    <TextArea id="character_appearance" name="character_appearance" rows="3" cols="25" placeholder="Description of physical appearance"/>

                                    <p>Relationships:</p>
                                    <TextArea id="character_relationships" name="character_relationships" rows="3" cols="25" placeholder="Overview of any relationships this character has - familial, romantic, friends, etc."/>

                                    <p>Trivia:</p>
                                    <TextArea id="character_trivia" name="character_trivia" rows="3" cols="25" placeholder="Little facts that don't fit in the personality/appearance sections with bullet points - character quirks, minor details, etc."/>


                                </React.Fragment>
                            }

                            {values.submission_type === "organization" &&
                                <React.Fragment>
                                    <h1>Organization Info</h1>

                                    <p>Name:</p>
                                    <TextField id="organization_name" name="organization_name"/>

                                    <p>Status:</p>
                                    <TextField id="organization_status" name="organization_status" placeholder="Active, Inactive, etc."/>

                                    <p>Type:</p>
                                    <TextField id="organization_type" name="organization_type"/>

                                    <p>Date Founded:</p>
                                    <TextField id="organization_founded" name="organization_founded"/>

                                    <p>Founder(s):</p>
                                    <TextField id="organization_founders" name="organization_founders"/>

                                    <p>Current Leader(s):</p>
                                    <TextField id="organization_leaders" name="organization_leaders"/>

                                    <p>Location:</p>
                                    <TextField id="organization_location" name="organization_location" placeholder="Major story locations the organization has been active"/>

                                    <p>Alliances:</p>
                                    <TextField id="organization_alliances" name="organization_alliances" placeholder="Factions or groups the organization is allied with, generally pertains to gangs"/>

                                    <p>Affiliations:</p>
                                    <TextField id="organization_affiliations" name="organization_affiliations" placeholder="Groups they are directly affiliated with, generally pertains to legal groups"/>

                                    <p>Current Members:</p>
                                    <TextField id="organization_members" name="organization_members"/>

                                    <p>Former Members:</p>
                                    <TextField id="organization_former_members" name="organization_former_members"/>

                                    <h1>Organization Description</h1>

                                    <p>Background:</p>
                                    <TextArea id="organization_background" name="organization_background" rows="5" cols="25" placeholder="Describe a bit about what this organization is, how they came to be etc."/>

                                    <p>Major Events:</p>
                                    <TextArea id="organization_events" name="organization_events" rows="4" cols="25" placeholder="Major story events that have occurred on the server."/>

                                    <p>Known Members:</p>
                                    <TextArea id="organization_member_info" name="organization_member_info" rows="4" cols="25" placeholder="Relevant information about members, how they joined/etc. List of members can go here."/>

                                    <p>Locations:</p>
                                    <TextArea id="organization_locations" name="organization_locations" rows="4" cols="25" placeholder="More information about where this group has been located. Again, keep to major story-heavy locations."/>

                                    <p>Trivia:</p>
                                    <TextArea id="organization_trivia" name="organization_trivia" rows="4" cols="25"/>

                                </React.Fragment>
                            }

                            <button type="submit" disabled={isSubmitting}>
                                Submit
                                <div className="wheel">
                                    <img src={ButtonWheel} alt={ButtonWheel}/>
                                </div>
                            </button>
                            <ErrorMessage name={"submit_error"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('submit_error', {smooth: true})}</p>}</ErrorMessage>

                            <PersistFormikValues name="wiki-submission" storage={"sessionStorage"} persistInvalid={true}/>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        );
    }
}

export default withGoogleReCaptcha(WikiSubmission);