import React, {Component} from "react";

import "./index.css";

import Article from "../../elements/article";
import MarkdownFile from "../../../articles/rules.md";
import {hideCaptchaBadge, hideVideoBackground, makeNavBackgroundOpaque} from "../../../utility/elementFinder";

export default class Rules extends Component {
    componentDidMount() {
        hideCaptchaBadge(true);
        hideVideoBackground(true);
        makeNavBackgroundOpaque(true);
    }

    render() {
        return (
            <Article markdownFilePath={MarkdownFile} />
        );
    }
}