import React, {Component} from "react";
import {isMobile} from "react-device-detect";
import {connect} from 'formik';
import _ from "lodash";

import "./index.css";

import TwitchLogo from '../../../assets/img/third-party/twitch-logo-white.svg'
import TwitterLogo from '../../../assets/img/third-party/twitter-logo-white.svg'
import DiscordLogo from '../../../assets/img/third-party/discord-logo-white.svg'
import SteamLogo from '../../../assets/img/third-party/steam-logo-white.svg'

export const LoginStrategies = {
    STEAM: "steam",
    DISCORD: "discord",
    TWITCH: "twitch",
    TWITTER: "twitter"
}

type LoginStrategyButtonProps = {
    loginStrategy: string,
    required: boolean,
    redirect: string,
    accountInfo: any
}

type LoginStrategyButtonState = {
    profileImage: any,
    profileName: any,
    profileId: any,
    thirdPartyLogo: any
}

class LoginStrategyButton extends Component<LoginStrategyButtonProps, LoginStrategyButtonState> {
    constructor(props) {
        super(props);

        let thirdPartyLogo = SteamLogo;

        switch (this.props.loginStrategy) {
            case LoginStrategies.DISCORD:
                thirdPartyLogo = DiscordLogo;
                break;
            case LoginStrategies.TWITCH:
                thirdPartyLogo = TwitchLogo;
                break;
            case LoginStrategies.TWITTER:
                thirdPartyLogo = TwitterLogo;
                break;
            default:
                thirdPartyLogo = SteamLogo;
                break;
        }

        this.state = {
            accountInfo: {},
            thirdPartyLogo: thirdPartyLogo
        }
    }

    async componentDidMount() {
        if (this.props.accountInfo === null || this.props.accountInfo === undefined || this.props.accountInfo === {}) return;

        try {
            let profileImage = "";
            let profileName = "";
            let profileId = "";

            switch (this.props.loginStrategy) {
                default:
                case LoginStrategies.STEAM:
                    if (!(LoginStrategies.STEAM in this.props.accountInfo)) break;

                    let steamInfo = this.props.accountInfo.steam;
                    profileId = steamInfo.steamid;
                    profileImage = steamInfo.avatarfull;
                    profileName = steamInfo.personaname;

                    break;
                case LoginStrategies.DISCORD:
                    if (!(LoginStrategies.DISCORD in this.props.accountInfo)) break;

                    let discordInfo = this.props.accountInfo.discord;
                    profileId = discordInfo.id;
                    profileImage = `https://cdn.discordapp.com/avatars/${discordInfo.id}/${discordInfo.avatar}.png?size=512`;
                    profileName = `${discordInfo.username}#${discordInfo.discriminator}`;

                    break;
                case LoginStrategies.TWITCH:
                    if (!(LoginStrategies.TWITCH in this.props.accountInfo)) break;

                    let twitchInfo = this.props.accountInfo.twitch;
                    profileId = twitchInfo.id;
                    profileImage = twitchInfo.profile_image_url;
                    profileName = twitchInfo.login;

                    break;
                case LoginStrategies.TWITTER:
                    if (!(LoginStrategies.TWITTER in this.props.accountInfo)) break;

                    let twitterInfo = this.props.accountInfo.twitter;
                    profileId = twitterInfo.id;
                    profileImage = twitterInfo.profile_image_url_https;
                    profileName = `@${twitterInfo.screen_name}`;

                    break;
            }

            this.props.formik.setFieldValue(`${this.props.loginStrategy}_id`, profileId)
            this.props.formik.setFieldValue(`${this.props.loginStrategy}_name`, profileName)

            if (this.props.loginStrategy === LoginStrategies.DISCORD && this.props.accountInfo.discord !== undefined) {
                let server = _.find(this.props.accountInfo.discord.guilds, ['id', '641831604624424962']);

                this.props.formik.setFieldValue('discord_server', server !== undefined);
                this.props.formik.setFieldValue('discord_icon', profileImage);
            }

            this.setState({
                profileImage: profileImage,
                profileName: profileName,
                profileId: profileId
            })
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        if (this.state.profileId === ""  || this.state.profileId === undefined) {
            return (
                <div id={`${this.props.loginStrategy}_id`} className={`login-strategy-wrapper ${isMobile ? "mobile" : ""}`}>
                    <div className="login-strategy-button" onClick={() => window.open(`/auth/${this.props.loginStrategy}?redirect=${this.props.redirect}`, '_self')}>
                        <div className="login-strategy-logo">
                            <img src={this.state.thirdPartyLogo} alt={""}/>
                        </div>
                        <div className="login-strategy-text">Attach {this.props.loginStrategy} Account</div>
                    </div>

                    {this.props.required &&
                        <div className={`login-strategy-account ${isMobile ? "mobile" : ""}`}>
                            <div className="login-strategy-required">Required</div>
                        </div>
                    }
                </div>
            )
        } else {
            return (
                <div id={`${this.props.loginStrategy}_id`} className={`login-strategy-wrapper ${isMobile ? "mobile" : ""}`}>
                    <div className="login-strategy-button" onClick={() => {
                        window.open(`/auth/${this.props.loginStrategy}/disconnect?redirect=${this.props.redirect}`, '_self')

                        this.props.formik.setFieldValue(`${this.props.loginStrategy}_id`, '')
                        this.props.formik.setFieldValue(`${this.props.loginStrategy}_name`, '')

                        if (this.props.loginStrategy === LoginStrategies.DISCORD && this.state.accountInfo.discord !== undefined) {
                            this.props.formik.setFieldValue('discord_server', false);
                            this.props.formik.setFieldValue('discord_icon', '');
                        }
                    }}>
                        <div className="login-strategy-logo">
                            <img src={this.state.thirdPartyLogo} alt={""}/>
                        </div>
                        <div className="login-strategy-text">Remove {this.props.loginStrategy} Account</div>
                    </div>

                    <div className={`login-strategy-account ${isMobile ? "mobile" : ""}`}>
                        <div className="profile-picture">
                            <img src={this.state.profileImage} alt={""}/>
                        </div>
                        <div className="profile-text">{this.state.profileName}</div>
                    </div>
                </div>
            );
        }
    }
}

export default connect(LoginStrategyButton);