import React, {Component} from "react";
import qs from "querystring-es3";

import "./index.css";

import Content from "../../elements/content";
import {hideCaptchaBadge, hideVideoBackground, makeNavBackgroundOpaque} from "../../../utility/elementFinder";

export default class Success extends Component {
    componentDidMount() {
        hideCaptchaBadge(true);
        hideVideoBackground(false);
        makeNavBackgroundOpaque(false);
    }

    render() {
        let type = qs.parse(this.props.location.search, {ignoreQueryPrefix: true})['?type'];

        let centerText;
        let blurb;
        let nextStep;

        switch (type)
        {
            case "allowlist":
                centerText = "Application Submitted!";
                blurb = "Woo! We've received your application. You're a few steps closer to being even more awesome!";
                nextStep = "It will take some time to review your application, about one to two weeks. If we want to pursue the possibility of granting you allowlist, a staff member will reach out via Discord. Make sure your Discord account is setup to receive messages from server members.";
                break;
            case "banappeal":
                centerText = "Appeal Submitted";
                blurb = "Your appeal has been received. Please allow staff some time to review.";
                nextStep = "Once our staff members have reached a conclusion concerning your appeal, we will reach out via the email you've included.";
                break;
            case "bugreport":
                centerText = "Report Submitted!";
                blurb = "So, you've found a bug ehh? We'll get this sent over to our bug-squashing department. I'm sure they have a flyswatter large enough... or maybe some sort of spray.";
                nextStep = "Thanks for submitting a bug report! An internal ticket has been opened and our staff members will being their bug-hunting process to verify and hopefully patch out the issue. We may reach out via Discord if we need some more information.";
                break;
            case "playerreport":
                centerText = "Report Submitted";
                blurb = "Player report received. An internal ticket will soon be opened where our staff members will launch an investigation based on the evidence provided.";
                nextStep = "We may reach out to ask for some more information or to inform you of any outcome if necessary. If you have any concerns or questions, please message the WildRP Support Bot.";
                break;
            case "staffapplication":
                centerText = "Application Submitted";
                blurb = "We have received your staff application! You better be super excited, cause we are!";
                nextStep = "Once staff reviews your application we will reach out via the support bot, so stay tuned! ";
                break;
            case "developerapplication":
                centerText = "Application Submitted";
                blurb = "We have received your developer application! You better be super excited, cause we are!";
                nextStep = "Once staff reviews your application we will reach out via the support bot, so stay tuned! ";
                break;
            case "lawapplication":
                centerText = "Application Submitted";
                blurb = "We have received your law application! Lets just hope you didn't shoot the deputy...";
                nextStep = "Once staff reviews your application we will reach out via the support bot, so stay tuned! ";
                break;
            case "wikisubmission":
                centerText = "Wiki Content Submitted";
                blurb = "We have received your submission of wiki content! Hopefully you check all of your spelling and grammar!";
                nextStep = "Once staff reviews the information, we will go ahead and create or update existing wiki pages!";
                break;
            default:
                centerText = "Success!?";
                blurb = "You've succeeded at something, but I can't seem to figure out what it is...";
                nextStep = "There is not much I can help you out with here. Maybe click the back button?"
                break;
        }

        return (
            <Content>
                <div className="success-wrapper">
                <span className="center-text">
                    {centerText}
                </span>

                    <span className="blurb">
                    {blurb}
                </span>

                    <p>{nextStep}</p>
                </div>
            </Content>
        );
    }
}