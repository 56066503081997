import React, {Component} from "react";
import {Form, Formik, ErrorMessage} from 'formik';
import {PersistFormikValues} from 'formik-persist-values';
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {scroller} from 'react-scroll';
import axios from "axios";
import {hideCaptchaBadge, hideVideoBackground, makeNavBackgroundOpaque} from "../../../utility/elementFinder";

import "./index.css";

import TextArea from "../../elements/textarea";
import FormWrapper from "../../elements/form-wrapper";
import LoginStrategyButton, {LoginStrategies} from "../../elements/login-strategy-button";
import Warning from "../../elements/warning";
import TextField from "../../elements/textfield";
import ButtonWheel from "../../../assets/img/wheel-gold.svg";
import CustomSelect from "../../elements/cusotm-select";
import PassportHolder from "../../elements/passport-holder";

const initialValues = {
    steam_id: '',
    steam_name: '',
    discord_id: '',
    discord_name: '',
    age: '',
    team: '',
    warnings: '',
    why_apply: '',
    membership: '',
    past_experience: '',
    volunteer_time: '',
    value_provide: '',
    change_something: '',
    other: ''
}

const teams = [
    {label: "Creative Direction", value: "creative_direction"},
    {label: "Community Support", value: "community_support"},
    {label: "Project Management", value: "project_management"},
    {label: "Quality Assurance", value: "quality_assurance"}
]

class StaffApplication extends Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        hideCaptchaBadge(false);
        hideVideoBackground(true);
        makeNavBackgroundOpaque(true);
    }

    handleValidate(values) {
        const errors = {};

        if (!values.steam_id) errors.steam_id = 'You must attach a Steam account in order to submit a staff application.';
        if (!values.discord_id) errors.discord_id = 'You must attach a Discord account in order to submit a staff application.';
        if (!values.age) errors.age = 'You must include your age in order to submit a staff application.';
        if (values.age && (isNaN(values.age) || parseInt(values.age) < 18)) errors.age = 'You must include a valid age in order to submit a staff application.'
        if (!values.team) errors.team = 'You must select which team you would like to apply for in order to submit a staff application.';
        if (!values.warnings) errors.warnings = 'You must describe any warnings or bans you have had order to submit a staff application.';

        return errors;
    }

    async handleSubmit(values, {setSubmitting, resetForm, setFieldError}) {
        let token = await this.props.googleReCaptchaProps.executeRecaptcha('submit_staffapplication');

        console.log(token)

        let request = {
            form: "staffapplication",
            captchaToken: token,
            values: values
        }

        await axios.post('/forms/staffapplication', request).then(res => {
            if ('data' in res && res.data.status !== undefined) {
                let status = res.data.status;

                if (status === "success") {
                    resetForm();
                    this.props.history.push('/success?type=staffapplication');
                    return;
                }
            }

            setFieldError("submit_error", "There was an error submitting your application, please try again. If this issue persists, please contact a WildRP staff member.")
        }).catch(error => {
            console.error(error);

            setFieldError("submit_error", "There was an error submitting your application, please try again. If this issue persists, please contact a WildRP staff member.")
        })

        setSubmitting(false);
    }

    render() {
        return (
            <FormWrapper>
                <div className="form-title">Staff Application</div>
                <Warning>
                    At WildRP we continue to push the boundaries of both what a roleplay community is and the way we support that community through game modifications.
                    Even with decades of combined experience amongst our staff team, we don't shy away from the fact that being the proprietors of the WildRP community is difficult and challenging work.
                    As such, we often require more help then we have available and actively seek out community members to bring their expertise to our team and help keep this amazing community together.
                    <br/><br/>
                    In general, our staff team can be seperated into four separate, but closely interlocked teams. If you would like to apply as a developer please see our <a href="/developer-application" target="_blank">developer application</a> to pursue that route.
                    <br/><br/>
                    Select the team dropdown below to learn more about each role and whether or not you might be a good fit!
                </Warning>
                <Formik initialValues={initialValues} onSubmit={this.handleSubmit} validate={this.handleValidate}>
                    {({isSubmitting, values}) => (
                        <Form>
                            <PassportHolder>
                                <LoginStrategyButton loginStrategy={LoginStrategies.STEAM} required={true} redirect="staff-application"/>
                                <ErrorMessage name={"steam_id"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('steam_id', {smooth: true})}</p>}</ErrorMessage>
                                <LoginStrategyButton loginStrategy={LoginStrategies.DISCORD} required={true} redirect="staff-application"/>
                                <ErrorMessage name={"discord_id"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('discord_id', {smooth: true})}</p>}</ErrorMessage>
                            </PassportHolder>

                            <h1>General Questions</h1>

                            <p>Please select the team you would like to apply for.</p>
                            <CustomSelect values={teams} id={"team"} placeholder={"Select Team..."}/>
                            <ErrorMessage name={"team"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('team', {smooth: true})}</p>}</ErrorMessage>

                            {values.team === "creative_direction" &&
                            <p>
                                The Creative Direction team works closely with all staff teams to create all the artwork and media seen in-game, on our social medias, and throughout the community.
                                <br/><br/>
                                Members of our Creative Direction team have a multitude of skills including, but not limited to: 3D modeling and animations, photographic and video filming/editing, social media management, and texture/digital design.
                                <br/><br/>
                                The following criteria must be met if you are considering applying for a position with our creative direction team:
                                <ol>
                                    <li>All staff members are expected to conduct themselves professionally and within WildRP's rules and guidelines when publicly representing the community and staff team.</li>
                                    <li>All staff members are required to be strong, calm, and well equipped communicators.</li>
                                    <li>In moderating the community, staff members must utilize WildRP's rules, Discord/Twitch ToS, and common sense before taking any vote or action.</li>
                                    <li>All staff members must prioritize community and server health of personal gameplay.</li>
                                </ol>
                                If you meet all of the criteria above, and are an allowlisted community member in good standing, we welcome you to apply!
                            </p>
                            }

                            {values.team === "community_support" &&
                            <p>
                                Members of our Community Support team, also known as Support Staff, focus on the day-to-day operations of our application and interview process for community members.
                                <br/><br/>
                                As Support Staff, you would be responsible for reviewing and voting on new player applications as well as interviewing applicants that make it past the initial review step.
                                <br/><br/>
                                Along with the aforementioned responsibilities, you must also meet the following criteria:
                                <ol>
                                    <li>All staff members are expected to conduct themselves professionally and within WildRP's rules and guidelines when publicly representing the community and staff team.</li>
                                    <li>All staff members are required to be strong, calm, and well equipped communicators.</li>
                                    <li>In moderating the community, staff members must utilize WildRP's rules, Discord/Twitch ToS, and common sense before taking any vote or action.</li>
                                    <li>All staff members must prioritize community and server health of personal gameplay.</li>
                                    <li>Members of the Community Support team must be able to assist with WildRP's application process and conduct vocal interviews.</li>
                                    <li>Members of the Community Support team must be available to cover at least two potential interviews per week in their normal schedules.</li>
                                </ol>
                                If you meet all of the criteria above, and are an allowlisted community member in good standing, we welcome you to apply!
                            </p>
                            }

                            {values.team === "project_management" &&
                            <p>
                                The Project Management team focuses on curating bug reports, server suggestions, managing development tickets, and a number of other tasks related to our development process.
                                <br/><br/>
                                As a Project Manager you would be responsible for maintaining a list of development priorities, moderating and conducting tests of in-development software, and verifying/reproducing bug reports.
                                <br/><br/>
                                Along with the aforementioned responsibilities, you must also meet the following criteria:
                                <ol>
                                    <li>All staff members are expected to conduct themselves professionally and within WildRP's rules and guidelines when publicly representing the community and staff team.</li>
                                    <li>All staff members are required to be strong, calm, and well equipped communicators.</li>
                                    <li>In moderating the community, staff members must utilize WildRP's rules, Discord/Twitch ToS, and common sense before taking any vote or action.</li>
                                    <li>All staff members must prioritize community and server health of personal gameplay.</li>
                                </ol>
                                If you meet all of the criteria above, and are an allowlisted community member in good standing, we welcome you to apply!
                            </p>
                            }

                            {values.team === "quality_assurance" &&
                                <p>
                                    This role is for people who like to break things. The QA testing team is involved in the logging, reproduction and testing of bugs and newly developed features.
                                    <br/><br/>
                                    Members of the testing team have a unique mindset in how they approach problems and are able to proactively identify requirements and test functional limits.
                                    <br/><br/>
                                    Experience in quality assurance and validation is preferred.
                                    <br/><br/>
                                    Along with the aforementioned responsibilities, you must also meet the following criteria:
                                    <ol>
                                        <li>All staff members are expected to conduct themselves professionally and within WildRP's rules and guidelines when publicly representing the community and staff team.</li>
                                        <li>All staff members are required to be strong, calm, and well equipped communicators.</li>
                                        <li>In moderating the community, staff members must utilize WildRP's rules, Discord/Twitch ToS, and common sense before taking any vote or action.</li>
                                        <li>All staff members must prioritize community and server health of personal gameplay.</li>
                                    </ol>
                                    If you meet all of the criteria above, and are an allowlisted community member in good standing, we welcome you to apply!
                                </p>
                            }

                            <p>What is your age?</p>
                            <TextField id="age" name="age"/>
                            <ErrorMessage name={"age"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('age', {smooth: true})}</p>}</ErrorMessage>

                            <p>Have you had any warnings or bans on WildRP or any other community?</p>
                            <TextArea id="warnings" name="warnings" rows="5" cols="25"/>
                            <ErrorMessage name={"warnings"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('warnings', {smooth: true})}</p>}</ErrorMessage>

                            <h1>About You</h1>

                            <p>How long have you been a member of the WildRP Community?</p>
                            <TextArea id="membership" name="membership" rows="5" cols="25"/>

                            <p>Why are you applying for this staff position?</p>
                            <TextArea id="why_apply" name="why_apply" rows="5" cols="25"/>

                            <p>What is your prior experience related to the team you are applying for? Furthermore, do you have experience being in an administrative or moderator position of an online community?</p>
                            <TextArea id="past_experience" name="past_experience" rows="5" cols="25"/>

                            <h1>The Job (Definitely not a heist)</h1>

                            <p>How much time would you be able to volunteer a week? Please take into account your normal schedule and what time you would be able to healthily commit!</p>
                            <TextField id="volunteer_time" name="volunteer_time"/>

                            <p>What are you able to bring to the table? What value would you be able to provide or add to the WildRP community in the role you are applying for?</p>
                            <TextArea id="value_provide" name="value_provide" rows="5" cols="25"/>

                            <p>If you could fix, improve, or change anything about WildRP, what would it be?</p>
                            <TextArea id="change_something" name="change_something" rows="5" cols="25"/>

                            <h1>The Final Details</h1>

                            <p>Are there any comments or questions you may have that are extensions or not related to the questions above?</p>
                            <TextArea id="other" name="other" rows="5" cols="25"/>

                            <button type="submit" disabled={isSubmitting}>
                                Submit Application
                                <div className="wheel">
                                    <img src={ButtonWheel} alt={ButtonWheel}/>
                                </div>
                            </button>
                            <ErrorMessage name={"submit_error"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('submit_error', {smooth: true})}</p>}</ErrorMessage>

                            <PersistFormikValues name="staff-application" ignoreValues={["steam_id", "steam_name", "discord_id", "discord_name", "discord_icon", "discord_server"]} storage={"sessionStorage"} persistInvalid={true}/>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        );
    }
}

export default withGoogleReCaptcha(StaffApplication);