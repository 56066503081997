import React, {Component} from "react";
import {Form, Formik, ErrorMessage} from 'formik';
import { PersistFormikValues } from 'formik-persist-values';
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {scroller} from 'react-scroll';
import axios from "axios";
import {hideCaptchaBadge, hideVideoBackground, makeNavBackgroundOpaque} from "../../../utility/elementFinder";

import "./index.css";

import TextArea from "../../elements/textarea";
import FormWrapper from "../../elements/form-wrapper";
import LoginStrategyButton, {LoginStrategies} from "../../elements/login-strategy-button";
import Warning from "../../elements/warning";
import TextField from "../../elements/textfield";
import ButtonWheel from "../../../assets/img/wheel-gold.svg";
import CustomSelect from "../../elements/cusotm-select";
import PassportHolder from "../../elements/passport-holder";

const initialValues = {
    steam_id: ' ',
    steam_name: '',
    discord_id: '',
    discord_name: '',
    service: '',
    log: '',
    screenshot_one: '',
    screenshot_two: '',
    screenshot_three: '',
    expected_outcome: '',
    actual_outcome: '',
    problem_description: '',
    steps_to_reproduce: ''
}

const service = [
    {label: "RedM Modification", value: "redm"},
    {label: "Website", value: "website"},
    {label: "Support Bot", value: "support_bot"},
    {label: "Launcher", value: "launcher"},
    {label: "Stream Deck Application", value: "stream_deck"}
]

class BugReport extends Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        hideCaptchaBadge(false);
        hideVideoBackground(true);
        makeNavBackgroundOpaque(true);
    }

    handleValidate(values) {
        const errors = {};

        if (!values.steam_id) errors.steam_id = 'You must attach a Steam account in order to submit a bug report.';
        if (!values.discord_id) errors.discord_id = 'You must attach a Discord account in order to submit a bug report.';
        if (!values.service) errors.service = 'You must select an affected service in order to submit a bug report.';

        return errors;
    }

    async handleSubmit(values, {setSubmitting, resetForm, setFieldError}) {
        let token = await this.props.googleReCaptchaProps.executeRecaptcha('submit_bugreport');

        console.log(token)

        let request = {
            form: "bugreport",
            captchaToken: token,
            values: values
        }

        await axios.post('/forms/bugreport', request).then(res => {
            if ('data' in res && res.data.status !== undefined) {
                let status = res.data.status;

                if (status === "success") {
                    resetForm();
                    this.props.history.push('/success?type=bugreport');
                    return;
                }
            }

            setFieldError("submit_error", "There was an error submitting your report, please try again. If this issue persists, please contact a WildRP staff member.")
        }).catch(error => {
            console.error(error);

            setFieldError("submit_error", "There was an error submitting your report, please try again. If this issue persists, please contact a WildRP staff member.")
        })

        setSubmitting(false);
    }

    render() {
        return (
            <FormWrapper>
                <div className="form-title">Bug Report</div>
                <Warning>
                    You are required to attach both your Steam and Discord accounts to bug reports. This allows staff members to reach out and get more information about the bug or issue.
                </Warning>
                <Formik initialValues={initialValues} onSubmit={this.handleSubmit} validate={this.handleValidate}>
                    {({ isSubmitting, values}) => (
                        <Form>
                            <PassportHolder>
                                <LoginStrategyButton loginStrategy={LoginStrategies.STEAM} required={true} redirect="bug-report"/>
                                <ErrorMessage name={"steam_id"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('steam_id', {smooth: true})}</p>}</ErrorMessage>
                                <LoginStrategyButton loginStrategy={LoginStrategies.DISCORD} required={true} redirect="bug-report"/>
                                <ErrorMessage name={"discord_id"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('discord_id', {smooth: true})}</p>}</ErrorMessage>
                            </PassportHolder>

                            <h1>General Questions</h1>

                            <p>Please select the system or service where you experienced this bug.</p>
                            <CustomSelect values={service} id={"service"} placeholder={"Select System/Service..."}/>
                            <ErrorMessage name={"service"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('service', {smooth: true})}</p>}</ErrorMessage>

                            <p>Submit a link after uploading your log file to a text sharing service such as <a href="https://pastebin.com" target="_new">Pastebin</a> or <a href="https://gist.github.com/" target="_new">Github Gist</a>. Do <b>NOT</b> submit a link to a discord attachment.</p>
                            <TextField id="log" name="log" placeholder="CitizenFX.log"/>

                            <p>Submit the links after uploading your screenshots to a image sharing service such as <a href="https://imgur.com" target="_new">Imgur</a> or <a href="https://gyazo.com/" target="_new">Gyazo</a>. Do <b>NOT</b> submit a link to a discord attachment.</p>
                            <TextField id="screenshot_one" name="screenshot_one" placeholder="Screenshot #1"/>

                            {(values.screenshot_one !== undefined && values.screenshot_one !== "") &&
                                <TextField id="screenshot_two" name="screenshot_two" placeholder="Screenshot #2"/>
                            }

                            {(values.screenshot_two !== undefined && values.screenshot_two !== "") &&
                            <TextField id="screenshot_three" name="screenshot_three" placeholder="Screenshot #3"/>
                            }

                            <h1>Bug Details</h1>

                            <p><b>Expected Outcome:</b> Please describe the expected outcome of the feature.</p>
                            <TextArea id="expected_outcome" name="expected_outcome" rows="3" cols="25"/>

                            <p><b>Actual Outcome:</b> Please describe the actual outcome of the feature.</p>
                            <TextArea id="actual_outcome" name="actual_outcome" rows="3" cols="25"/>

                            <p>Please describe the disconnect between the expected and actual outcomes and why/how it can cause an issue or create other problems.</p>
                            <TextArea id="problem_description" name="problem_description" rows="5" cols="25"/>

                            <p>With as much detail as possible, please describe all the steps to reproduce the issue.</p>
                            <TextArea id="steps_to_reproduce" name="steps_to_reproduce" rows="5" cols="25" placeholder="1. Step One&#10;2. Step Two&#10;3. Step Three&#10;4. Step Four"/>

                            <button type="submit" disabled={isSubmitting}>
                                Submit Report
                                <div className="wheel">
                                    <img src={ButtonWheel} alt={ButtonWheel}/>
                                </div>
                            </button>
                            <ErrorMessage name={"submit_error"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('submit_error', {smooth: true})}</p>}</ErrorMessage>

                            <PersistFormikValues name="bug-report" ignoreValues={["steam_id", "steam_name", "discord_id", "discord_name", "discord_icon", "discord_server"]} storage={"sessionStorage"} persistInvalid={true}/>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        );
    }
}

export default withGoogleReCaptcha(BugReport);