import React, {Component} from "react";

import "./index.css";
import {BrowserView} from "react-device-detect";

import Stamp from '../../../assets/img/stamp.png'

export default class FormWarning extends Component {
    render() {
        return (
            <div className="warning">
                <BrowserView className="warning-stamp">
                    <img src={Stamp} alt=""/>
                </BrowserView>
                <div className="warning-content">{this.props.children}</div>
            </div>
        );
    }
}
