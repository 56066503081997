import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {BrowserView, MobileView} from "react-device-detect";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'

import "./index.css";

import Logo from '../../../assets/img/wild-stamp-logo.png'
import YoutubeLogo from '../../../assets/img/third-party/youtube-logo-white.svg'
import TwitterLogo from '../../../assets/img/third-party/twitter-logo-white.svg'
import DiscordLogo from '../../../assets/img/third-party/discord-logo-white.svg'

function GetTempNavButton(rawLocation, location, any) {
    if (location.length !== 0 && (any || (location !== "RULES" && location !== "FAQ" && location !== "APPLICATION"))) {
        return (
            <div className="link-wrapper active">
                {location.replaceAll('-', ' ')}
            </div>
        )
    }

    return null;
}

function NavBar() {
    const [state, setState] = useState({showingMore: false});

    let rawLocation = useLocation().pathname
    let location = rawLocation.substring(1).toUpperCase();

    const moreButtonRef = useRef(null);
    const drownDownRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if ((moreButtonRef.current && !moreButtonRef.current.contains(event.target)) && (drownDownRef.current && !drownDownRef.current.contains(event.target))) {
                setState({showingMore: false})
            }
        }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [moreButtonRef, drownDownRef]);

    return (
        <div className="navbar-wrapper" id="nav">
            <div className="navbar-content">
                <div className="navbar-left">
                    <BrowserView>
                        {GetTempNavButton(rawLocation, location, false)}

                        <div className={`link-wrapper ${location === "RULES" ? "active" : ""}`}>
                            <Link className="link" to="/rules">
                                RULES
                            </Link>
                        </div>

                        <div className={`link-wrapper ${location === "FAQ" ? "active" : ""}`}>
                            <Link to="/faq">
                                FAQ
                            </Link>
                        </div>

                        <div className={`link-wrapper ${location === "APPLICATION" ? "active" : ""}`}>
                            <Link to="/application">
                                APPLICATION
                            </Link>
                        </div>

                        <div className="link-wrapper">
                            <a href="https://wiki.wildrp.com">
                                WIKI
                            </a>
                        </div>
                    </BrowserView>

                    <MobileView>
                        {GetTempNavButton(rawLocation, location, true)}
                    </MobileView>

                    <div className={`link-wrapper ${state.showingMore ? "active" : ""}`}>
                        <button ref={moreButtonRef} onClick={() => setState({showingMore: !state.showingMore})}>
                            MORE <FontAwesomeIcon icon={faCaretDown}/>
                        </button>
                    </div>
                </div>

                <Link to="/">
                    <img className="logo" src={Logo} alt={""}/>
                </Link>

                <BrowserView className="navbar-right">
                    <div className="link-wrapper link-accent">
                        <a href="https://storage.googleapis.com/wildrp-launcher-downloads/WildRPLauncherSetup.exe">
                            Download Launcher
                        </a>
                    </div>

                    <div className={"nav-divider"} />

                    <div className="third-party-logo">
                        <a href="https://www.youtube.com/c/WildRP">
                            <img src={YoutubeLogo} alt={""}/>
                        </a>
                    </div>

                    <div className="third-party-logo">
                        <a href="https://twitter.com/WildRPofficial">
                            <img src={TwitterLogo} alt={""}/>
                        </a>
                    </div>

                    <div className="third-party-logo">
                        <a href="https://discord.gg/wildrp">
                            <img src={DiscordLogo} alt={""}/>
                        </a>
                    </div>
                </BrowserView>
            </div>

            <div className={`dropdown-wrapper ${state.showingMore ? "active" : ""}`} ref={drownDownRef} onClick={() => setState({showingMore: !state.showingMore})}>
                <div className="dropdown-background">
                    <div className={`dropdown-content ${state.showingMore ? "active" : ""}`}>
                        <MobileView className="dropdown-column">
                            <div className={`link-wrapper-block`}>
                                <Link className="link" to="/rules">
                                    RULES
                                </Link>
                            </div>

                            <div className={`link-wrapper-block`}>
                                <Link to="/faq">
                                    FAQ
                                </Link>
                            </div>

                            <div className={`link-wrapper-block`}>
                                <Link to="/application">
                                    APPLICATION
                                </Link>
                            </div>

                            <div className="link-wrapper-block">
                                <a href="https://wiki.wildrp.com">
                                    WIKI
                                </a>
                            </div>
                        </MobileView>
                        <div className="dropdown-column">
                            <div className={`link-wrapper-block`}>
                                <Link to="/bug-report">
                                    BUG REPORT
                                </Link>
                            </div>
                            <div className={`link-wrapper-block`}>
                                <Link to="/player-report">
                                    PLAYER REPORT
                                </Link>
                            </div>
                            <div className={`link-wrapper-block`}>
                                <Link to="/ban-appeal">
                                    BAN APPEAL
                                </Link>
                            </div>
                        </div>
                        <div className="dropdown-column">
                            <div className={`link-wrapper-block`}>
                                <Link to="/staff-application">
                                    STAFF APPLICATION
                                </Link>
                            </div>
                            <div className={`link-wrapper-block`}>
                                <Link to="/developer-application">
                                    DEVELOPER APPLICATION
                                </Link>
                            </div>
                            <div className={`link-wrapper-block`}>
                                <Link to="/law-application">
                                    LAW APPLICATION
                                </Link>
                            </div>
                        </div>
                        {/*<div className="dropdown-column">*/}
                        {/*    <div className={`link-wrapper-block`}>*/}
                        {/*        <Link to="/job-application">*/}
                        {/*            JOB APPLICATION*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*    <div className={`link-wrapper-block`}>*/}
                        {/*        <Link to="/twitch-team-application">*/}
                        {/*            TWITCH TEAM APPLICATION*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*    <div className={`link-wrapper-block`}>*/}
                        {/*        <Link to="/branding-guide">*/}
                        {/*            BRANDING GUIDE*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        <div className="dropdown-column">
                            <div className={`link-wrapper-block`}>
                                <Link to="/wiki-submission">
                                    WIKI SUBMISSION
                                </Link>
                            </div>
                            <div className={`link-wrapper-block`}>
                                <Link to="/privacy-policy">
                                    PRIVACY POLICY
                                </Link>
                            </div>
                            <div className="link-wrapper-block">
                                <a href="https://wildrp.statuspage.io">
                                    SERVER STATUS
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default NavBar;