import React, {Component} from "react";
import {Form, Formik, ErrorMessage} from 'formik';
import {PersistFormikValues} from 'formik-persist-values';
import {withGoogleReCaptcha} from "react-google-recaptcha-v3";
import {scroller} from 'react-scroll';
import axios from "axios";
import {hideCaptchaBadge, hideVideoBackground, makeNavBackgroundOpaque} from "../../../utility/elementFinder";

import "./index.css";

import TextArea from "../../elements/textarea";
import FormWrapper from "../../elements/form-wrapper";
import LoginStrategyButton, {LoginStrategies} from "../../elements/login-strategy-button";
import Warning from "../../elements/warning";
import TextField from "../../elements/textfield";
import ButtonWheel from "../../../assets/img/wheel-gold.svg";
import PassportHolder from "../../elements/passport-holder";

const initialValues = {
    steam_id: '',
    steam_name: '',
    discord_id: '',
    discord_name: '',
    age: '',
    warnings: '',
    why_apply: '',
    code_experience: '',
    code_language: '',
    volunteer_time: '',
    group_setting: '',
    planned_feature: '',
    other: ''
}
class DeveloperApplication extends Component {
    constructor() {
        super();

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        hideCaptchaBadge(false);
        hideVideoBackground(true);
        makeNavBackgroundOpaque(true);
    }

    handleValidate(values) {
        const errors = {};

        if (!values.steam_id) errors.steam_id = 'You must attach a Steam account in order to submit a staff application.';
        if (!values.discord_id) errors.discord_id = 'You must attach a Discord account in order to submit a staff application.';
        if (!values.age) errors.age = 'You must include your age in order to submit a staff application.';
        if (values.age && (isNaN(values.age) || parseInt(values.age) < 18)) errors.age = 'You must include a valid age in order to submit a staff application.'
        if (!values.warnings) errors.warnings = 'You must describe any warnings or bans you have had order to submit a staff application.';

        return errors;
    }

    async handleSubmit(values, {setSubmitting, resetForm, setFieldError}) {
        let token = await this.props.googleReCaptchaProps.executeRecaptcha('submit_developerapplication');

        console.log(token)

        let request = {
            form: "developerapplication",
            captchaToken: token,
            values: values
        }

        await axios.post('/forms/developerapplication', request).then(res => {
            if ('data' in res && res.data.status !== undefined) {
                let status = res.data.status;

                if (status === "success") {
                    resetForm();
                    this.props.history.push('/success?type=developerapplication');
                    return;
                }
            }

            setFieldError("submit_error", "There was an error submitting your application, please try again. If this issue persists, please contact a WildRP staff member.")
        }).catch(error => {
            console.error(error);

            setFieldError("submit_error", "There was an error submitting your application, please try again. If this issue persists, please contact a WildRP staff member.")
        })

        setSubmitting(false);
    }

    render() {
        return (
            <FormWrapper>
                <div className="form-title">Developer Application</div>
                <Warning>
                    At WildRP we continue to push the boundaries of both what a roleplay community is and the way we support that community through game modifications.
                    Even with decades of combined experience amongst our staff team, we don't shy away from the fact that being the proprietors of the WildRP community is difficult and challenging work.
                    As such, we often require more help then we have available and actively seek out community members to bring their expertise to our team and help keep this amazing community together.
                </Warning>
                <Formik initialValues={initialValues} onSubmit={this.handleSubmit} validate={this.handleValidate}>
                    {({isSubmitting, values}) => (
                        <Form>
                            <PassportHolder>
                                <LoginStrategyButton loginStrategy={LoginStrategies.STEAM} required={true} redirect="developer-application"/>
                                <ErrorMessage name={"steam_id"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('steam_id', {smooth: true})}</p>}</ErrorMessage>
                                <LoginStrategyButton loginStrategy={LoginStrategies.DISCORD} required={true} redirect="developer-application"/>
                                <ErrorMessage name={"discord_id"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('discord_id', {smooth: true})}</p>}</ErrorMessage>
                            </PassportHolder>

                            <h1>General Questions</h1>

                            <p>What is your age?</p>
                            <TextField id="age" name="age"/>
                            <ErrorMessage name={"age"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('age', {smooth: true})}</p>}</ErrorMessage>

                            <p>Have you had any warnings or bans on WildRP or any other community?</p>
                            <TextArea id="warnings" name="warnings" rows="5" cols="25"/>
                            <ErrorMessage name={"warnings"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('warnings', {smooth: true})}</p>}</ErrorMessage>

                            <h1>About You</h1>

                            <p>Why are you applying for the developer position?</p>
                            <TextArea id="why_apply" name="why_apply" rows="5" cols="25"/>

                            <p>What experience do you have with coding and programming, can you explain any previous projects you’ve worked on?</p>
                            <TextArea id="code_experience" name="code_experience" rows="5" cols="25"/>

                            <p>What code language are you most comfortable with?</p>
                            <TextArea id="code_language" name="code_language" rows="5" cols="25"/>

                            <h1>The Job</h1>

                            <p>How much time would you be able to volunteer a week? Please take into account your normal schedule and what time you would be able to healthily commit!</p>
                            <TextField id="volunteer_time" name="volunteer_time"/>

                            <p>Are you able to work well within a group setting?</p>
                            <TextArea id="group_setting" name="group_setting" rows="5" cols="25"/>

                            <p>Explain any features or systems in short detail that you would like to develop if given approval?</p>
                            <TextArea id="planned_feature" name="planned_feature" rows="5" cols="25"/>

                            <h1>The Final Details</h1>

                            <p>Are there any comments or questions you may have that are extensions or not related to the questions above?</p>
                            <TextArea id="other" name="other" rows="5" cols="25"/>

                            <button type="submit" disabled={isSubmitting}>
                                Submit Application
                                <div className="wheel">
                                    <img src={ButtonWheel} alt={ButtonWheel}/>
                                </div>
                            </button>
                            <ErrorMessage name={"submit_error"}>{msg => <p className="error-message">{msg}{scroller.scrollTo('submit_error', {smooth: true})}</p>}</ErrorMessage>

                            <PersistFormikValues name="dev-application" ignoreValues={["steam_id", "steam_name", "discord_id", "discord_name", "discord_icon", "discord_server"]} storage={"sessionStorage"} persistInvalid={true}/>
                        </Form>
                    )}
                </Formik>
            </FormWrapper>
        );
    }
}

export default withGoogleReCaptcha(DeveloperApplication);